/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GlobalStyle from "./globalStyles";
import Header from "./header"
import "./layout.css"
import {useEffect} from "react";
import Footer from "./footer";

const Layout = ({ children, lang, department, otherLang, defLang, location, refs }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Header department={department} refs={refs} lang={lang} otherLang={otherLang} defLang={defLang} location={location} />
      <div>
        <main>{children}</main>
        {/*<footer*/}
        {/*  style={{*/}
        {/*    marginTop: `2rem`,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  © {new Date().getFullYear()}, Built with*/}
        {/*  {` `}*/}
        {/*  <a href="https://www.gatsbyjs.com">Gatsby</a>*/}
        {/*</footer>*/}
          <Footer lang={lang} refs={refs} defLang={defLang} location={location} />
      </div>
    </>
  )
}

export default Layout
