import React from "react"
import styled from "styled-components"
import {colors} from "../variables";
import LogoFooter from "../images/logo-footer.svg"
import {Link} from "gatsby";
import {localNavLinks} from "./header"

const Footer = styled(({className, lang, refs, defLang, location}) => {
    const scrollToPosition = ( element, e) => {
        e.preventDefault()
        // console.log(element)
        element.current.scrollIntoView({ behavior: "smooth"})
    }
    return (
        <div className={className}>
            <div className="wrapper">
                <div className="logo">
                    <img src={LogoFooter} alt=""/>
                    <p>Design your future</p>
                </div>
                <div className="link">
                    {/*<a onClick={scrollToPosition.bind(this, refs.serviceRef)}>{localNavLinks.services.label[lang]}</a>*/}
                    {/*{ location.pathname === '/' || location.pathname === `/${lang}` || location.pathname === `/${lang}/` ? <a onClick={scrollToPosition.bind(this, refs.serviceRef)} href="#">{localNavLinks.services.label[lang]}</a> :*/}
                    {/*    <Link to={`/${defLang !== lang ? lang + "/#servicesec" : "#servicesec"}`}>{localNavLinks.services.label[lang]}</Link>*/}
                    {/*}*/}
                    {/*<Link to={localNavLinks.portfolio.link[lang]}>{localNavLinks.portfolio.label[lang]}</Link>*/}
                    <p><span>Powered by <a target="_blank" href="https://greenflamingobcn.com">Green Flamingo </a></span> / © Distinct {new Date().getFullYear()}</p>
                </div>
            </div>
        </div>
    )
})`
  border-top: 4px solid ${colors.orange};
  @media (min-width: 960px) {
    border-top: 8px solid ${colors.orange};
  }
  height: auto;å
  @media (min-width: 768px) {
    height:105px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:30px 0;
  background: ${colors.lightGreyText};
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap:wrap;
    padding:0 25px;
    max-width: 1200px;
    margin:auto;
    width:100%;
    height:100%;
  }
  .link {
    display: flex;
    justify-content: flex-start;
    @media (min-width: 960px) {
      justify-content: flex-end;
    }
    //align-self: flex-end;
    width:100%;
    text-transform: uppercase;
    font-size: 13px;
    p {
      margin:0;
      line-height:12px;
      font-size:14px;
      color: #6c6b68;
      font-weight:bold;
      span {
        font-size:12px;
        font-weight:normal;
      }
      a {
        text-decoration:none;
        color:inherit;
        font-size:inherit;
        &:hover {
          text-decoration:underline;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
    @media (min-width:768px) {
      width:50%;
      font-size:15px;
    }
    //max-width:300px;
    a {
      display: inline-block;
      text-transform: uppercase;
      color:#000000;
      text-decoration: none;
      font-size:16px;
    }
  }
  .logo {
    width:100%;
    margin-bottom:20px;
    p {
      text-transform: uppercase;
      font-weight: bold;
      line-height:12px;
    }
    @media (min-width: 768px) {
      width:50%;
      margin-bottom:0;
    }
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap:wrap;
    p {
      margin-bottom:0;
      width:100%;
      margin-top:30px;
      @media (min-width: 960px) {
        width:auto;
        margin-top:0;
      }
    }
    img {
      max-width: 50px;
      display: block;
      margin-right:30px;
      margin-bottom:0;
    }
  }
`

export default Footer
