import React, {useState} from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import styled from "styled-components"
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs"
import { useMediaQuery } from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'
import {colors} from "../variables";
import {StaticImage} from "gatsby-plugin-image";
import LogoImg from "../images/logo.svg"

export const localNavLinks = {
    contact: {
        label: {
            sr: "Kontakt",
            en: "Contact"
        },
        link: {
            sr: "",
            en: ""
        }
    },
    services: {
        label: {
            sr: "Usluge",
            en: "Services"
        },
        link: {
            sr: "",
            en: ""
        }
    },
    portfolio: {
        label: {
            sr: "Portfolio",
            en: "Portfolio"
        },
        link: {
            sr: "/portfolio",
            en: "/en/portfolio"
        }
    },
    blog: {
        label: {
            sr: "Blog",
            en: "Blog"
        },
        link: {
            sr: "/blog",
            en: "/en/blog"
        }
    }
}

const HeaderWrapper = styled.div`
  display:none;
  @media (min-width:960px) {
    display:flex;
  }
  justify-content: space-between;
  align-items: center;
  width:100%;
  max-width:1200px;
  margin:auto;
  height:100px;
  a {
    display: block;
    margin:0 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000000;
    font-size:16px;
  }
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 30%;
  @media (min-width: 960px) {
    margin-top:20px;
  }
  a {
    margin:0;
  }
  div {
    margin-right:25px;
    display:flex;
    &:last-child {
      margin-right:50px;
      position:relative;
      a {
        position: relative;
        display: block;
        transition: all .3s ease;
        &:after {
          position: absolute;
          content: "";
          height:2px;
          bottom:0;
          left:50%;
          width:0;
          background:${colors.orange};
          transition: all .3s ease;
        }
        &:before {
          position: absolute;
          content: "";
          height:2px;
          bottom:0;
          right:50%;
          width:0;
          background:${colors.orange};
          transition: all .3s ease;
        }
        &:hover {
          &:before, &:after {
            width:50%;
          }
        }
      }
    }
  }
`
const Logo = styled.div`
  display: flex;
  max-width: 40%;
  width:40%;
  justify-content: center;
  img {
    display: block;
    max-width: 250px;
    margin-bottom:0;
  }
`
const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 960px) {
    margin-top:25px;
  }
  .lang {
    a {
      &:after, &:before {
        content: "";
        height:0;
        width:0;
        &:hover {
          width:0;
        }
      }
    }
  }
  a {
    position: relative;
    display:block;
    &:focus {
      outline:none !important;
    }
    &:after {
      position: absolute;
      content: "";
      height:2px;
      bottom:0;
      left:50%;
      width:0;
      background:${colors.orange};
      transition: all .3s ease;
    }
    &:before {
      position: absolute;
      content: "";
      height:2px;
      bottom:0;
      right:50%;
      width:0;
      background:${colors.orange};
      transition: all .3s ease;
    }
    &:hover {
      &:before, &:after {
        width:50%;
      }
    }
  }
`

const Header = styled(({ className, siteTitle, lang, otherLang, defLang, refs, location, department }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)'
  })
    const [isOpen, setIsOpen] = useState(false)

    const scrollToPosition = ( element, e) => {
        e.preventDefault()
        element.current.scrollIntoView({ behavior: "smooth"})
        if(!isDesktopOrLaptop) {
            // console.log("Ovo je mobilna verzija")
            setIsOpen(false)
        }
    }
 return ( <header className={className}>
   <HeaderWrapper>
     <SocialIcons>
       <div>
           <a target="_blank" rel="noreferrer"  href={department.contacts.find(c => c.type === 'SOCIAL_FACEBOOK').value}><BsFacebook size={30}/></a>
       </div>

       <div>
           <a target="_blank" rel="noreferrer"  href={department.contacts.find(c => c.type === 'SOCIAL_INSTAGRAM').value}><BsInstagram size={30}/></a>
       </div>

       <div>
           <a target="_blank" rel="noreferrer"  href={department.contacts.find(c => c.type === 'SOCIAL_LINKEDIN')?.value || "#"}><BsLinkedin size={30}/></a>
       </div>

       <div>
         { location.pathname === '/' || location.pathname === `/${lang}` || location.pathname === `/${lang}/` ? <a onClick={scrollToPosition.bind(this, refs.contactRef)} href="#">{localNavLinks.contact.label[lang]}</a> :
             <Link to={`/${defLang !== lang ? lang + "/#contact" : "#contact"}`}>{localNavLinks.contact.label[lang]}</Link>
         }

       </div>
     </SocialIcons>

     <Logo>
         <Link to={`${defLang === lang ? "/" : "/" + lang }`}>
             {/*<StaticImage*/}
             {/*    src={"../images/logo.svg"}*/}
             {/*    quality={95}*/}
             {/*    alt="A Gatsby astronaut"*/}
             {/*/>*/}
             <img src={LogoImg} alt="Distinct logo"/>
         </Link>
     </Logo>

     <NavLinks>
         { location.pathname === '/' || location.pathname === `/${lang}` || location.pathname === `/${lang}/` ? <a onClick={scrollToPosition.bind(this, refs.serviceRef)} href="#">{localNavLinks.services.label[lang]}</a>  :
             <Link to={`/${defLang !== lang ? lang + "/#services": "#services"}`}>{localNavLinks.services.label[lang]}</Link>
         }
       {/*<a onClick={scrollToPosition.bind(this, refs.serviceRef)} href="#">{localNavLinks.services.label[lang]}</a>*/}
       <Link to={localNavLinks.portfolio.link[lang]}>{localNavLinks.portfolio.label[lang]}</Link>
       {/*<Link to={localNavLinks.blog.link[lang]}>{localNavLinks.blog.label[lang]}</Link>*/}
         <div className="lang">
             {otherLang.map((ol, index) => {
                 return  <Link style={{width:"100%"}} key={index} to={ol.value.indexOf('index') > - 1 ? ol.value.replace('/index', '/') : ol.value}>
                 {/*    {ol.iso === "sr" &&  <StaticImage*/}
                 {/*    src="../images/montenegroflag.png"*/}
                 {/*    width={34}*/}
                 {/*    quality={95}*/}
                 {/*    formats={["auto", "webp", "avif"]}*/}
                 {/*    alt="A Gatsby astronaut"*/}
                 {/*/> }*/}
                 {/*    {ol.iso === "en" &&  <StaticImage*/}
                 {/*        src="../images/britainflag.png"*/}
                 {/*        width={34}*/}
                 {/*        quality={95}*/}
                 {/*        formats={["auto", "webp", "avif"]}*/}
                 {/*        alt="A Gatsby astronaut"*/}
                 {/*    /> }*/}
                     {ol.iso === 'sr' ? 'mn' : ol.iso}
                 </Link>
             })}
         </div>
     </NavLinks>
   </HeaderWrapper>
    <div className="mobile-top-header">
        <Logo>
            <Link to={`${defLang === lang ? "/" : "/" + lang }`}>
                {/*<StaticImage*/}
                {/*  src={"../images/logo.svg"}*/}
                {/*  width={250}*/}
                {/*  quality={95}*/}
                {/*  alt="Distinct logo"*/}
                {/*/>*/}
                <img src={LogoImg} alt="Distinct logo"/>
            </Link>
        </Logo>
    </div>
    <div className="mobile-menu">
        <Menu isOpen={isOpen} onOpen={() => {setIsOpen(true)}} onClose={() => {setIsOpen(false)}}>
            {/*<Link to="#">Usluge</Link>*/}
            { location.pathname === '/' || location.pathname === `/${lang}` || location.pathname === `/${lang}/` ? <a onClick={scrollToPosition.bind(this, refs.serviceRef)} href="#">{localNavLinks.services.label[lang]}</a>  :
                <Link to={`/${defLang !== lang ? lang + "/#services": "#services"}`}>{localNavLinks.services.label[lang]}</Link>
            }
            <Link onClick={() => setIsOpen((prev) => !prev)} to={localNavLinks.portfolio.link[lang]}>{localNavLinks.portfolio.label[lang]}</Link>
            {/*<Link to={localNavLinks.blog.link[lang]}>{localNavLinks.blog.label[lang]}</Link>*/}
            {/*<Link to="#">{localNavLinks.contact.label[lang]}</Link>*/}
            { location.pathname === '/' || location.pathname === `/${lang}` || location.pathname === `/${lang}/` ? <a onClick={scrollToPosition.bind(this, refs.contactRef)} href="#">{localNavLinks.contact.label[lang]}</a> :
                <Link to={`/${defLang !== lang ? lang + "/#contact" : "#contact"}`}>{localNavLinks.contact.label[lang]}</Link>
            }
            <div className="lang" style={{borderBottom: "none"}}>
                {otherLang.map((ol, index) => {
                    return  <Link style={{width:"100%"}} key={index} to={ol.value.indexOf('index') > - 1 ? ol.value.replace('/index', '/') : ol.value}>
                        {/*{ol.iso === "sr" &&  <StaticImage*/}
                        {/*    src="../images/montenegroflag.png"*/}
                        {/*    width={34}*/}
                        {/*    quality={95}*/}
                        {/*    formats={["auto", "webp", "avif"]}*/}
                        {/*    alt="A Gatsby astronaut"*/}
                        {/*    style={{ marginBottom: `1.45rem` }}*/}
                        {/*/> }*/}
                        {/*{ol.iso === "en" &&  <StaticImage*/}
                        {/*    src="../images/britainflag.png"*/}
                        {/*    width={34}*/}
                        {/*    quality={95}*/}
                        {/*    formats={["auto", "webp", "avif"]}*/}
                        {/*    alt="A Gatsby astronaut"*/}
                        {/*    style={{ marginBottom: `1.45rem` }}*/}
                        {/*/> }*/}
                        {ol.iso === 'sr' ? 'mn' : ol.iso}
                    </Link>
                })}
            </div>
            <SocialIcons>
                <div>
                    <a target="_blank" rel="noreferrer"  href={department.contacts.find(c => c.type === 'SOCIAL_FACEBOOK').value}><BsFacebook size={30}/></a>
                </div>

                <div>
                    <a target="_blank" rel="noreferrer"  href={department.contacts.find(c => c.type === 'SOCIAL_INSTAGRAM').value}><BsInstagram size={30}/></a>
                </div>

                <div>
                    <a target="_blank" rel="noreferrer"  href={department.contacts.find(c => c.type === 'SOCIAL_LINKEDIN')?.value || "#"}><BsLinkedin size={30}/></a>
                </div>

            </SocialIcons>
        </Menu>
    </div>

  </header> )
})`
  width:100%;
  position:sticky;
  top:0;
  background: white;
  z-index:1000;
  @media (min-width: 960px) {
    border-bottom: 7px solid ${colors.orange};
  }
  .mobile-top-header {
    display:flex;
    //top:0;
    //position:sticky;
    width:100%;
    padding:0 25px;
    ${Logo} {
      width:100%;
      max-width:130px;
    }
    height:80px;
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff;
    border-bottom:3px solid ${colors.orange};
    //box-shadow: 0 0 17px ${colors.lightGreyText};
    z-index:100;
    &:focus {
      outline: none !important;
    }
    img {
      width:100%;
      display:block;
    }
    @media (min-width: 960px) {
      display: none;
    }
  }
  @media (min-width: 960px) {
    .mobile-menu {
      display:none;
    }
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
