import { createGlobalStyle } from 'styled-components';
import { colors } from "../variables";
import {SocialIcons} from "./header";

const GlobalStyle = createGlobalStyle`
  html {
    width:100%;
    height:100%;
  }
  #___gatsby {
    height:100%;
  }
  body {
    color: ${colors.primaryText};
    position:relative;
    width:100%;
    height:100%;
  }
  #gatsby-focus-wrapper {
    overflow-x: hidden;
    height:100%;
  }
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    //top: 36px;
    top:25px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #050536;
    height:10% !important;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #04042a;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${colors.orange};
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top:0;
    width:100% !important;
    max-width:500px;
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${colors.whiteShade};
    //padding: 2.5em 1.5em 0;
    font-size: 16px;
    //border-right:5px solid ${colors.orange};
    a {
      text-decoration: none;
      color:#000000;
      &:focus {
        outline: none !important;
      }
    }
    ${SocialIcons} {
      display:flex !important;
      position:absolute;
      bottom:0;
      max-width: 100%;
      width:100%;
      div {
        width:35px;
      }
    }
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    //padding: 0.8em;
    padding-top:3.3em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: ${colors.primaryText};
    margin-bottom: 20px;
    padding: 0 .8em 1em 2.3em;
    border-bottom: 1px solid ${colors.orange};
    &:last-child {
      border-bottom:none;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top:0;
  }
  .bottom-line {
    position:absolute;
    top:100%;
    width:100%;
    height:7px;
    border-top: 1px solid ${colors.lightGreyText};
    border-bottom: 1px solid ${colors.lightGreyText};
  }
`;

export default GlobalStyle;
